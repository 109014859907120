<template>
  <div class="row">
    <div class="col-12">
      <h2 class="mb-0">{{ account.bank }} - {{ formatIban(account.iban) }}
        <span v-if="showBalance"> | {{ formatCurrency(account.current_balance) }}</span>
        <span v-else><i @click="showBalance=true" class="fas fa-face-tongue-money cursor-pointer"></i></span>
        <br>
      </h2>
      <div class="mb-3 opacity-50">{{ $t('transactions.last_updated_at')}}: {{ formatDate(account.last_transactions_crawl, 'datetime') }}</div>
    </div>
    <div class="col-12" v-if="userStore.user.hasPermission(PERMISSIONS.ACCOUNTS.CREATE)">
      <router-link class="btn btn-info me-2"
                   :to="{name: ROUTES.ACCOUNTS.IMPORT_TRANSACTIONS, params: {id: $route.params.id}}"
                   :title="$t('accounts.import_transactions')">
        <i class="fas fa-file-import"/>
      </router-link>
      <router-link class="btn btn-info" :to="{name: ROUTES.ACCOUNTS.ANALYTICS, params: {id: $route.params.id}}"
                   :title="$t('accounts.analytics.heading')">
        <i class="fas fa-chart-line"/>
      </router-link>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-12 col-lg-4">
          <DatePicker v-model="daterange" range multi-calendars @update:model-value="handleDateRange"
                      :enable-time-picker="false"/>
        </div>
        <div class="col-12 col-lg-2">
          <button class="btn btn-primary" @click="setLastMonth">{{ $t('accounts.last_month') }}</button>
        </div>
        <div class="col-12 col-lg-2">
          <template v-if="userStore.user.hasPermission(PERMISSIONS.ACCOUNTS.VIEW)"> <!-- FIXME: PERMISSIONS.ACCOUNT_TRANSACTIONS.EXPORT -->
            <button class="btn btn-primary btn-sm" type="button" @click="exportCSV">{{ $t('actions.export_csv') }}</button>
          </template>
        </div>
      </div>
    </div>
    <div class="col-12">

      <DataTable :lazy="true"
                 :paginator="true"
                 :rows="transactionStore.serverOptions.rows"
                 :totalRecords="transactionStore.itemLength"
                 v-model:filters="filters"
                 filterDisplay="row"
                 :globalFilterFields="['remittance_info','counterparty_name']"
                 dataKey="id"
                 editMode="cell"
                 :rowClass="rowClass"
                 class="editable-cells-table"
                 @cell-edit-complete="onCellEditComplete"
                 :value="account_transactions"
                 :loading="transactionStore.loading"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[50,100,200]"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                 @page="onPage($event)"
                 @filter="onFilter($event)"
                 responsiveLayout="scroll">
        <TableColumn field="booking_date" :header="this.$t('transactions.booking_date')">
          <template #body="{ data }">
            <div class="" :title="`${this.$t('transactions.value_date')}: ${data.value_date}`" :data-id="data.id">
              {{ data.booking_date }}
            </div>
          </template>
        </TableColumn>
        <TableColumn field="counterparty_name" :header="this.$t('transactions.counterparty_name')">
          <template #filter="{filterModel,filterCallback}">
            <input type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
          </template>
          <template #body="{ data }">
            <div @click="showIban = !showIban">
              {{ data.counterparty_name }}
            </div>
            <div v-if="showIban">
              {{ data.counterparty_iban }}
            </div>
          </template>
        </TableColumn>
        <TableColumn field="remittance_info" :header="this.$t('transactions.remittance_info')">
          <template #filter="{filterModel,filterCallback}">
            <input type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
          </template>
        </TableColumn>
        <TableColumn class="currency" field="amount" :header="this.$t('transactions.amount')" dataType="numeric">
          <template #filter="{filterModel,filterCallback}">
            <div class=" d-flex">
              <select v-model="filterModel.matchMode" @change="filterCallback">
                <option :value="FilterMatchMode.EQUALS">==</option>
                <option :value="FilterMatchMode.LESS_THAN_OR_EQUAL_TO">&lt;=</option>
                <option :value="FilterMatchMode.GREATER_THAN_OR_EQUAL_TO">&gt;=</option>
              </select>
              <input type="number" v-model="filterModel.value" @input="filterCallback"
                     class="p-column-filter text-end hide-number-specific-btns"
                     style="width: 6em"
                     step="0.01"/>
            </div>
          </template>
        </TableColumn>
        <TableColumn field="note" :header="this.$t('transactions.note')" style="max-width: 200px">
          <template #editor="{ data, field }">
            <input v-model="data[field]" autofocus class="w-100"/>
          </template>
        </TableColumn>
        <TableColumn field="tags" :header="this.$tc('accounts.tag', 2)">
          <template #filter="{filterModel,filterCallback}">
              <Multiselect
                      mode="multiple"
                      :hideSelected="false"
                      class="tags-filter-multiselect form-control p-0"
                      :options=availableTags
                      v-model="filterModel.value"
                      @change="filterCallback()"
                      valueProp="name"
                      label="name"
                      :searchable="true"
                      placeholder="Tags"
              >
              </Multiselect>
          </template>
          <template #body="{ data, index }">
            <div v-if="data.tags.length > 0" @click="openTagsDialog(data, index)">
              {{ data.tags.map((t) => t.name).sort().join(', ') }}
            </div>
            <span v-else class="" @click="openTagsDialog(data, index)">
              <i class="fa fa-plus-circle"></i>
            </span>
          </template>
        </TableColumn>
        <TableColumn v-if="!userStore.user.specialRole" :header="this.$t('accounts.operations')">
          <template #body="{data}">
            <div class="operations-wrapper">
              <div>
                <a class="cursor-pointer" data-bs-toggle="modal" data-bs-target="#receiptsModal"
                   @click.prevent="openReceipts(data.id)"
                   :title="$t('receipts.search')">
                  <span class="fa-stack">
                      <i class="fas fa-receipt fa-stack-2x"/>
                      <i class="fas fa-search fa-stack-1x" style="color:black"/>
                  </span>
                </a>
                <a class="cursor-pointer" data-bs-toggle="modal" data-bs-target="#invoicesModal"
                   @click.prevent="openInvoices(data)"
                   :title="$t('invoices.search')">
                  <span class="fa-stack">
                      <i class="fas fa-fw fa-file-invoice-dollar fa-stack-2x"/>
                      <i class="fas fa-fw fa-search fa-stack-1x fa-inverse" style="color:black"/>
                  </span>
                </a>
                <router-link class="cursor-pointer"
                             :to="{name: ROUTES.RECEIPTS.CREATE, query: {amount: data.raw_amount, transaction_id: data.id, name: data.counterparty_name, paid_at: data.raw_booking_date, intermediate_user_id: userStore.user.id}}"
                             :title="$t('receipts.create')">
                  <span class="fa-stack">
                      <i class="fas fa-receipt fa-stack-2x "/>
                      <i class="fas fa-plus fa-stack-1x" style="color:black"/>
                    </span>
                </router-link>
              </div>
            </div>
          </template>
        </TableColumn>
        <TableColumn :header="this.$tc('receipts.receipt', 2)">
          <template #body="{data}">
            <div>
              <template v-if="data.receipts.length > 0">
                <a :href="receipt.file_url" :key="index" :title="receipt.file_name" v-for="(receipt, index) in data.receipts" target="_blank">
                    <i class="fas fa-receipt"/> <span v-html="receipt.file_name.truncate(25)"></span>
                </a>
              </template>
              <template :key="index" v-for="(invoice, index) in data.invoices">
                <router-link class="d-block"
                             :to="{name: ROUTES.DUNNING.EDIT, params: {invoice_id: invoice.id}}"
                             @click.prevent="showInvoice(invoice.id)"
                >
                  <i class="fas fa-file-invoice-dollar"/> {{ invoice.number }}
                </router-link>
              </template>
            </div>
          </template>
        </TableColumn>
        <TableColumn field="audited" :header="this.$t('transactions.audited')">
          <template #filter="{filterModel,filterCallback}">
            <select v-model="filterModel.value" @change="filterCallback">
              <option :value="null">alle</option>
              <option :value="true">Ja</option>
              <option :value="false">Nein</option>
            </select>
          </template>
          <template #body="{data, index}">
            <div>
              <Toggle v-model="data.audited" @change="toggleAudited(data.id, $event, index)"/>
            </div>
          </template>
        </TableColumn>
        <ColumnGroup type="footer">
            <TableRow>
                <TableColumn :colspan="3" footerStyle="text-align:right" >
                    <template #footer>
                        <i class="fa-regular fa-sigma"></i>
                    </template>
                </TableColumn>
                <TableColumn :footer="formatCurrency(rowSums.amount)" footerStyle="text-align:right" />
            </TableRow>
        </ColumnGroup>
      </DataTable>
    </div>
    <ModalReceipts/>
    <ModalInvoices/>
    <Dialog v-model:visible="tagsDialog" :style="{width: '450px'}" header="Tags" :modal="true" class="p-fluid">
      <div class="field">
        <Multiselect
            label="name"
            valueProp="id"
            @search-change="searchTags"
            mode="tags"
            v-model="currentTag.selectedTags"
            :object="true"
            :searchable="true"
            :placeholder="$t('accounts.search_tag')"
            :options="tagList"
        />
      </div>
      <template #footer>
        <button class="btn btn-success" @click="saveTags">{{ $t('save') }}</button>
        <button class="btn btn-info" @click="hideTagsDialog">{{ $t('close') }}</button>
      </template>
    </Dialog>
    <DynamicDialog/>
  </div>
</template>

<script>
import {useAccountStore} from "@/stores/account";
import {useTransactionStore} from "@/stores/transactions";
import {useAccountTransactionTagStore} from "@/stores/account_transaction_tag";
import {useReceiptStore} from "@/stores/receipt";
import {useUserStore} from "@/stores/user";
import {Helpers} from "@/helpers";
import ModalReceipts from "./components/ModalReceipts.vue";
import ModalInvoices from "./components/ModalInvoices.vue";
import Dialog from "primevue/dialog";
import DynamicDialog from 'primevue/dynamicdialog';
import Toggle from '@vueform/toggle'
import Multiselect from '@vueform/multiselect'
import {FilterMatchMode} from "primevue/api";
import {defineAsyncComponent} from "vue";
import {PERMISSIONS, ROUTES, SPECIAL_ROLES} from "@/scripts/constants";
import dayjs from "dayjs";

const DunningEdit = defineAsyncComponent(() => import('../dunning/Edit.vue'));
export default {
  name: 'AccountsShow',
  components: {
    ModalReceipts,
    ModalInvoices,
    Dialog,
    DynamicDialog,
    Toggle,
    Multiselect
  },
  setup() {
    return {
      accountStore: useAccountStore(),
      transactionStore: useTransactionStore(),
      receiptStore: useReceiptStore(),
      userStore: useUserStore(),
      accountTransactionTagStore: useAccountTransactionTagStore(),
    }
  },
  data() {
    return {
      daterange: null,
      showBalance: this.userStore.user.specialRole === SPECIAL_ROLES.taxOffice,
      showIban: false,
      filters: {
        'counterparty_name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'remittance_info': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'audited': {value: null, matchMode: FilterMatchMode.EQUALS},
        'amount': {value: null, matchMode: FilterMatchMode.EQUALS},
        'start': {value: null, matchMode: FilterMatchMode.DATE_AFTER},
        'end': {value: null, matchMode: FilterMatchMode.DATE_BEFORE},
        'tags': {value: null, matchMode: FilterMatchMode.EQUALS},
      },
      tagsDialog: false,
      showInvoiceDialog: false,
      currentTag: {
        transactionId: null,
        selectedTags: null,
        transactionIndex: null
      },
      timer: null,
    }
  },
  computed: {
    FilterMatchMode() {
      return FilterMatchMode
    },
    availableTags() {
      return [{name: '<none>'}].concat(this.accountTransactionTagStore.tags)
    },
    rowSums() {
      const sums = {
        amount: 0,
      }
      for (let i = 0; i < this.transactionStore.transactions.length; i++) {
        const amount = parseFloat(this.transactionStore.transactions[i].amount)
        if (!isNaN(amount)) {
          sums.amount += amount
        }
      }
      return sums
    },
    PERMISSIONS() {
      return PERMISSIONS
    },
    ROUTES() {
      return ROUTES
    },
    tagList() {
      return this.accountTransactionTagStore.tags
    },
    account() {
      return this.accountStore.currentAccount
    },
    account_transactions() {
      return this.transactionStore.transactions.map((transaction) => {
        return {
          booking_date: this.$d(transaction.booking_date, 'short2'),
          raw_booking_date: transaction.booking_date,
          value_date: transaction.value_date ? this.$d(transaction.value_date, 'short2') : undefined,
          counterparty_name: transaction.counterparty_name,
          counterparty_iban: transaction.counterparty_iban,
          remittance_info: transaction.remittance_info,
          amount: this.formatCurrency(transaction.amount),
          raw_amount: transaction.amount,
          tags: transaction.tags,
          note: transaction.note,
          receipts: transaction.receipts,
          invoices: transaction.invoices,
          id: transaction.id,
          audited: transaction.audited,
          account_id: transaction.account_id,
        }
      })
    },
  },
  created() {
    if (this.$route.query.start ||
        this.$route.query.end ||
        this.$route.query.audited ||
        this.$route.query.remittance_info ||
        this.$route.query.counterparty_name) {

      if (this.$route.query.start) {
        this.filters.start.value = this.$route.query.start
      }
      if (this.$route.query.end) {
        this.filters.end.value = this.$route.query.end
      }
      if (this.$route.query.audited) {
        this.filters.audited.value = this.$route.query.audited
      }
      if (this.$route.query.remittance_info) {
        this.filters.remittance_info.value = this.$route.query.remittance_info
      }
      if (this.$route.query.counterparty_name) {
        this.filters.counterparty_name.value = this.$route.query.counterparty_name
      }
      this.onFilter({filters: this.filters})
    } else {
      this.getTransactions()
    }
    this.accountStore.get(this.$route.params.id)
    this.accountTransactionTagStore.index(null, 999)
  },
  updated() {
    this.setTitle(`${this.account.bank} - ${this.account.iban}`)
  },
  methods: {
    formatIban(iban) {
      if (!iban) {
        return
      }

      // https://daschmi.de/iban-mit-vanilla-javascript-bei-eingabe-formatieren/
      let v = iban.toUpperCase().replace(/ /g, '');
      let r = '';

      for (let i = 0; i < v.length; i ++) {
        r += v[i];
        if ((i + 1) % 4 === 0 && i > 0) r += ' ';
      }
      return r
    },
    rowClass(data) {
        const amount = parseFloat(data.raw_amount)
        return [{
            'row-positive': amount > 0,
            'row-negative': amount < 0,
        }];
    },
    exportCSV() {
      this.transactionStore.getForAccount(this.$route.params.id, true)
    },
    setLastMonth() {
      const now = dayjs();
      const lastMonthStart = now.month(now.month() - 1).startOf('month');
      const lastMonthEnd = lastMonthStart.endOf('month')
      this.daterange = [lastMonthStart, lastMonthEnd]
      this.handleDateRange()
    },
    handleDateRange() {
      const start = dayjs(this.daterange[0]).format('YYYY-MM-DD')
      const end = dayjs(this.daterange[1]).format('YYYY-MM-DD')
      this.filters.start.value = start
      this.filters.end.value = end
      this.onFilter({filters: this.filters})
    },
    onPage(event) {
      this.transactionStore.serverOptions = event
      this.getTransactions()
    },
    getTransactions() {
      this.transactionStore.getForAccount(this.$route.params.id)
    },
    openReceipts(transactionId) {
      Helpers.emitter.emit('openReceiptsModal', transactionId)
    },
    openInvoices(transaction) {
      Helpers.emitter.emit('openInvoicesModal', transaction)
    },
    showInvoice(id) {
      const dialogContent = new DunningEdit({props: {xxx: false}})
      // this.$dialog.open(DunningEdit, {
      this.$dialog.open(dialogContent, {
        data: {
          invoiceIdProp: id,
        },
        props: {
          style: {
            width: '100vw',
          },
          modal: true
        },
      });
    },
    saveTags() {
      this.transactionStore.update(this.$route.params.id, this.currentTag.transactionId, {tags: this.currentTag.selectedTags}).then(() => {
        this.transactionStore.transactions[this.currentTag.transactionIndex].tags = this.currentTag.selectedTags
      })
    },
    searchTags(query) {
      if (!query) {
        return false
      }
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.accountTransactionTagStore.index({search: query})
      }, 500);
    },
    onFilter(data) {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.transactionStore.serverOptions.page = 0
        this.transactionStore.serverOptions.filters = data.filters
        this.getTransactions()
      }, 500);
    },
    openTagsDialog(transaction, index) {
      this.tagsDialog = true
      this.currentTag.transactionId = transaction.id
      this.currentTag.transactionIndex = index
      if (transaction.tags.length > 0) {
        this.currentTag.selectedTags = transaction.tags
      }
    },
    hideTagsDialog() {
      this.tagsDialog = false
    },
    toggleAudited(transactionId, audited, index) {
      this.transactionStore.update(this.$route.params.id, transactionId, {audited}).then(({data}) => {
        this.transactionStore.transactions[index].audited = audited
        Helpers.emitter.emit('success', data.message)
      })
    },
    onCellEditComplete(e) {
      this.transactionStore.update(this.$route.params.id, e.data.id, {note: e.newData.note || ''}).then(({data}) => {
        this.transactionStore.transactions[e.index].note = e.newData.note
        Helpers.emitter.emit('success', data.message)
      })
    }
  },
  watch: {}
}
</script>
<style lang="scss">
  .row-negative {
    background: #fdd1d1;
  }
  .row-positive {
    background: #ebffeb;
  }
  .tags-filter-multiselect {
    width: 120px !important;
  }
</style>
